<template>
  <div>
    <h2 class="mb-5">A Kinetic Study - Catalase: Data & Calculations - Part C</h2>

    <h3 class="mb-4">Data</h3>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table1Caption"
        prefix="Table 1. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 3</td>
            <td style="font-weight: bold; text-align: center">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              V
              <chemical-latex content="(KI)" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vKICT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vKICT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vKICT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitKIC" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              V
              <chemical-latex content="(H2O2)" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2CT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2CT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O2CT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitH2O2C" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              V
              <chemical-latex content="(total)" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vTotCT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vTotCT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vTotCT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitVtotC" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="\%~H2O2" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pctH2O2CT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pctH2O2CT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pctH2O2CT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <stemble-latex content="$\%$" />
            </td>
          </tr>

          <tr>
            <td>Temperature</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempCT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempCT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempCT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitTempC" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Initial Rate</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateCT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateCT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateCT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitInitRateC" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h3 class="mb-4">Calculations</h3>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table2Caption"
        prefix="Table 2. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Result</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 3</td>
            <td style="font-weight: bold; text-align: center">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Temperature</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempFT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempFT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.tempFT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitTempF" :items="unitOptions2" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Initial Rate</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateCT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateCT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.initRateCT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitInitRateC" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>1/T</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.inverseTempFT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.inverseTempFT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.inverseTempFT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select
                v-model="inputs.unitInverseTempF"
                :items="unitOptions2"
                outlined
                hide-details
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>ln(Initial Rate)</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnInitRateFT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnInitRateFT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.lnInitRateFT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <stemble-latex content="$\text{N/A}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-3">
      Please upload a single pdf file containing your graph for this part of the experiment using
      the input field below. In the text field below the upload field, please provide an appropriate
      title for the graph.
    </p>

    <p class="mb-n2">
      <v-file-input v-model="attachments" multiple accept="application/pdf" />
    </p>

    <p class="mb-5">
      <v-text-field v-model="inputs.graphPartCTitle" label="Enter title here" />
    </p>

    <p class="mb-3">
      Based on the data collected in this part of the experiment, what is the activation energy for
      the decomposition of hydrogen peroxide when catalyzed by potassium iodide?
    </p>

    <calculation-input
      v-model="inputs.activationEnergyF"
      class="mb-5"
      prepend-text="$\text{E}_\text{a}:$"
      append-text="$\text{kJ/mol}$"
      :disabled="!allowEditing"
    />

    <p class="mb-2 mt-2">
      In the space provided below, please show your work for the calculation of the activation
      energy that you reported above.
    </p>

    <p class="mb-n2">
      <s-textarea v-model="inputs.eaCalcWork" outlined />
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OttawaLab5ReportSheetPartC',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        table1Caption: null,
        vKICT1: null,
        vKICT2: null,
        vKICT3: null,
        vH2O2CT1: null,
        vH2O2CT2: null,
        vH2O2CT3: null,
        vTotCT1: null,
        vTotCT2: null,
        vTotCT3: null,
        pctH2O2CT1: null,
        pctH2O2CT2: null,
        pctH2O2CT3: null,
        tempCT1: null,
        tempCT2: null,
        tempCT3: null,
        initRateCT1: null,
        initRateCT2: null,
        initRateCT3: null,
        unitKIC: null,
        unitH2O2C: null,
        unitVtotC: null,
        unitTempC: null,
        unitInitRateC: null,

        table2Caption: null,
        tempFT1: null,
        tempFT2: null,
        tempFT3: null,
        inverseTempFT1: null,
        inverseTempFT2: null,
        inverseTempFT3: null,
        lnInitRateFT1: null,
        lnInitRateFT2: null,
        lnInitRateFT3: null,
        unitTempF: null,
        unitInverseTempF: null,

        graphPartCTitle: null,

        activationEnergyF: null,
        eaCalcWork: null,
      },
      unitOptions: [
        '°C',
        'g',
        'g/L',
        'mL/drop',
        'kg',
        'mL',
        'mol/L',
        'kPa/s',
        '°F',
        'g/mL',
        'mol/kg',
        'm/s',
        'min',
        'M/min',
      ],
      unitOptions2: [
        '$\\text{g}^{-1}$',
        'L/g',
        '$^\\circ\\text{R}$',
        '$^\\circ\\text{R}^{-1}$',
        'mol/L',
        'kPa/s',
        '$^\\circ\\text{F}$',
        '$^\\circ\\text{F}^{-1}$',
        '$\\text{K}$',
        '$\\text{K}^{-1}$',
        'm/s',
        'min',
        '$\\text{min}^{-1}$',
        'M/min',
      ],
      attachments: [],
    };
  },
};
</script>

<style></style>
